<template>
	<div :key="isPortrait" class="mobile-auth-menu" :class="{ 'mobile-auth-menu__transition' : !dragged, 'mobile-auth-menu__open' : isPortrait || isOpen }" :style="{ bottom: bottom + 'px' }">
		<button class="button button--no-style mobile-auth-menu__avatar" :class="{ 'mobile-auth-menu__avatar-open' : isOpen }" @click="isOpen = !isOpen">
			<transition name="fade" :duration="200">
				<c-store-provided-avatar v-if="!isOpen" />
				<inline v-else svg="chevron-right" class="ansel bg-lange w-100 h-100 p-1" />
			</transition>
		</button>
		<transition name="slide-toggle" mode="out-in">
			<div v-if="isPortrait || isOpen" class="mobile-auth-menu__container" @touchstart="clicked = true">
				<button ref="handle" class="mobile-auth-menu__handle bg-brady flex flex--align w-100 p border-0" @keyup.enter="tapHandle" @mousedown="clicked = true" @mouseup="tapHandle">
					<c-store-provided-avatar />
					<span class="ansel heavy ml-2">{{ name }}</span>
				</button>
				<ul ref="content" class="no-list-style no-underline mobile-auth-menu__content bg-mapplethorpe p w-100">
					<li><a href="/myaccount" :disabled="disableLinks">My Account</a></li>
					<li v-show="orgCode"><a :href="`/${orgCode}`" :disabled="disableLinks">Dashboard</a></li>
					<li><router-link to="/support" :disabled="disableLinks" @click.native="$emit('closeMenu')">Support</router-link></li>
					<li>
						<button class="button button--no-style bg-brady px-3 py-2" :disabled="disableLinks" @click="$emit('logout')">
							Log Out
						</button>
					</li>
				</ul>
			</div>
		</transition>
	</div>
</template>
<script>
	import CStoreProvidedAvatar from '@cognitoforms/shared-components/src/components/auth/StoreProvidedAvatar.vue';

	export default {
		name: 'CMobileAuthMenu',
		components: { CStoreProvidedAvatar },
		props: {
			name: { type: String, default: null },
			orgCode: { type: String, default: null }
		},
		data() {
			return {
				bottom: 0,
				clicked: false,
				isOpen: false,
				dragged: false,
				isPortrait: true
			};
		},
		computed: {
			disableLinks() {
				return !this.isOpen && !this.dragged;
			}
		},
		mounted() {
			this.checkAspectRatio();
			window.addEventListener('resize', this.checkAspectRatio);
		},
		beforeDestroy() {
			this.removeEventListeners();
			document.removeEventListener('resize', this.checkAspectRatio);
		},
		methods: {
			handleDrag(e, isTouch) {
				// If clicked, calculate the position of the user's mouse/finger
				if (this.clicked) {
					const contentHeight = this.$refs.content.clientHeight;
					this.dragged = true;
					const cursorPos = isTouch ? e.touches[0].pageY : e.pageY;
					const touchPos = window.innerHeight - cursorPos - (this.$refs.handle.clientHeight / 2);
					if (touchPos >= contentHeight)
						this.bottom = contentHeight;
					else if (touchPos <= 0)
						this.bottom = 0;
					else
						this.bottom = touchPos;
				}
			},
			handleDragEnd() {
				this.clicked = false;
				if (this.$refs.content) {
					const contentHeight = this.$refs.content.clientHeight;
					// Snap to the top if partially opened
					if (this.isOpen) {
						if (this.bottom < contentHeight) {
							this.bottom = 0;
							this.isOpen = false;
						}
						else {
							this.bottom = contentHeight;
							this.isOpen = true;
						}
					}
					else {
						if (this.bottom > 0) {
							this.bottom = contentHeight;
							this.isOpen = true;
						}
						else {
							this.bottom = 0;
							this.isOpen = false;
						}
					}
				}

				this.dragged = false;
			},
			tapHandle() {
				if (this.isPortrait) {
					const contentHeight = this.$refs.content.clientHeight;
					// Toggle if they clicked the handle
					if (!this.dragged) {
						if (this.bottom === 0) {
							this.bottom = contentHeight;
							this.isOpen = true;
						}
						else {
							this.bottom = 0;
							this.isOpen = false;
						}
					}
				}
			},
			addEventListeners() {
				document.addEventListener('touchmove', (e) => {
					this.handleDrag(e, true);
				});
				document.addEventListener('mousemove', (e) => {
					this.handleDrag(e, false);
				});
				document.addEventListener('touchend', this.handleDragEnd);
				document.addEventListener('mouseup', this.handleDragEnd);
			},
			removeEventListeners() {
				document.removeEventListener('touchmove', (e) => {
					this.handleDrag(e, true);
				});
				document.removeEventListener('mousemove', (e) => {
					this.handleDrag(e, false);
				});
				document.removeEventListener('touchend', this.handleDragEnd);
				document.removeEventListener('mouseup', this.handleDragEnd);
			},
			async checkAspectRatio() {
				await this.$nextTick();
				const element = document.querySelector('.mobile-auth-menu__avatar');

				if (element) {
					const isPortrait = window.getComputedStyle(element).display === 'none';

					this.isPortrait = isPortrait;
					if (isPortrait)
						this.addEventListeners();
					else
						this.removeEventListeners();
				}
			}
		}
	};
</script>

<style lang="scss" src="@cognitoforms/styles/global/_motion.scss"></style>

<style lang="scss">
.mobile-auth-menu {
	z-index: $z1;
	flex-shrink: 0;

	&__transition {
		transition: bottom .25s;
	}

	&__avatar {
		position: fixed;
		bottom: 2rem;
		right: 2rem;
		/* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
		z-index: $z2;
		width: 4em;
		height: 4em;
		border-radius: 50%;
		overflow: hidden;
		transition: all .25s;

		&-open {
			width: 1.7em;
			height: 1.7em;
			transition: all .25s;
		}

		.avatar__image,
		svg.i-chevron {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
		}

		.avatar__image {
			border: 2px solid white;
		}
	}

	&__handle {
		appearance: none;
		outline: none;

		.avatar__image {
			width: 2em;
			height: 2em;
			border: 2px solid white;
		}
	}

	li {

		button {
			padding: .75em 1.25em;
			color: inherit;
		}

		a {
			display: inline-block;
			margin-bottom: 1em;
			color: $ansel;
			transition: .25s;

			&:hover {
				color: $matins;
				transition: .25s;
			}
		}
	}

	// landscape
	@media screen and (min-aspect-ratio: 2/3) {
		right: 0;
		min-height: 100px;
		overflow-y: scroll;
		-ms-overflow-style: none;
		scrollbar-width: none;
		transition: width 1s;

		&__open {
			width: 250px;
		}

		&::-webkit-scrollbar {
			display: none;
		}

		&__container {
			display: flex;
			flex-direction: column;
			min-height: 100%;
		}

		&__handle {
			pointer-events: none;
			background-color: $mapplethorpe;
			padding-bottom: 0;
			text-align: left;
		}

		&__content.no-list-style {
			flex-grow: 1;
			padding-top: 1em;
		}

		li:last-child {
			margin-bottom: 9em;
		}
	}

	// portrait
	@media screen and (max-aspect-ratio: 2/3) {
		position: fixed;
		width: 100%;

		:root &__avatar {
			display: none;
		}

		&__handle {
			justify-content: center;
			background-color: $brady;
			cursor: grab;

			&::after {
				position: absolute;
				left: 50%;
				top: 10px;
				width: 40px;
				height: 4px;
				background-color: $hitchcock;
				border-radius: 2px;
				transform: translateX(-50%);
				content: '';
			}
		}

		&__content {
			position: absolute;
			text-align: center;
		}

		li:last-child {
			margin-bottom: 1em;
		}
	}
}
</style>