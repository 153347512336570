<template>
	<div v-if="branch" class="branch-banner hide-banner">
		<svg class="branch-banner__icon" viewBox="0 0 50 50" fill="white"><path d="M34.14,12.23a5.39,5.39,0,0,0-2.7,10.06v.76A5.83,5.83,0,0,1,26,28.46a11.13,11.13,0,0,0-5.41,1.22V16.88a5.41,5.41,0,1,0-5.41,0v17.7a5.4,5.4,0,1,0,8.11,4.65,5.45,5.45,0,0,0-1.43-3.68A5,5,0,0,1,26,33.82c5.41,0,10.81-5.41,10.81-10.81v-.77a5.38,5.38,0,0,0-2.7-10ZM17.91,9.53a2.71,2.71,0,1,1-2.7,2.7A2.72,2.72,0,0,1,17.91,9.53Zm0,32.45a2.71,2.71,0,1,1,2.71-2.71A2.73,2.73,0,0,1,17.91,42ZM34.14,20.34a2.7,2.7,0,1,1,2.7-2.7A2.71,2.71,0,0,1,34.14,20.34Z" /></svg>
		<span>{{ branch }}</span>
		<span class="branch-banner__time">{{ branchTime }}</span>
		<span class="branch-banner__toggle" title="ctrl+b to toggle visibility">?</span>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				branch: process.env.COGNITO_BRANCH,
				branchTime: new Date(process.env.COGNITO_BRANCHTIME).toLocaleString()
			};
		},
		mounted() {
			document.addEventListener('keyup', this.handleKeyUp);
		},
		beforeDestroy() {
			document.removeEventListener('keyup', this.handleKeyUp);
		},
		methods: {
			handleKeyUp(e) {
				if (e.key === 'b' && e.ctrlKey) {
					toggleBranch();
				}

				function toggleBranch() {
					const branchEl = document.querySelector('.branch-banner');
					if (branchEl.classList.contains('hide-banner'))
						branchEl.classList.remove('hide-banner');
					else
						branchEl.classList.add('hide-banner');
				}
			}
		}
	};
</script>

<style  lang="scss">
.branch-banner {
	position: fixed;
	left: 0;
	top: calc(100% - 30px);
	z-index: $z-overkill;
	height: 30px;
	background: black;
	padding-left: 32px;
	transform-origin: 30px 30px;
	color: white;
	font-size: 12px;
	line-height: 30px;
	transition: .3s;

	&__icon {
		position: absolute;
		left: 10px;
		width: 20px;
		margin-top: 4px;
	}

	&__toggle {
		display: inline-block;
		position: absolute;
		bottom: 0;
		right: 0;
		top: 0;
		width: 16px;
		background: 0 0;
		border: 0;
		cursor: default;
		color: #ccc;
		font-size: 16px;
		font-weight: 700;
	}

	&.hide-banner {
		transform: rotate(-180deg);
	}

	&__time {
		margin-left: 16px;
		padding-right: 30px;
		color: #aaa;
		font-size: 10px;
	}
}
</style>