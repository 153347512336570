<template>
	<nav class="resources-menu">
		<div class="resources-menu__links">
			<ul class="columns pb-2">
				<li>
					<router-link to="/support">
						<h3>Support</h3>
						<p>Explore tutorials on form-building, calculations, payment, data integrations and more.</p>
					</router-link>
				</li>

				<li>
					<router-link to="/industries">
						<h3>Industry Guides</h3>
						<p>See in-depth guides to build forms and manage workflows for specific industries.</p>
					</router-link>
				</li>

				<li>
					<router-link to="/blog/tags/customer-stories">
						<h3>Customer Stories</h3>
						<p>Gather ideas and inspiration from others who automate work using Cognito Forms.</p>
					</router-link>
				</li>

				<li>
					<router-link to="/blog/tags/best-practices">
						<h3>Best Practices</h3>
						<p>Learn to build better forms and leverage them for greater impact on your organization.</p>
					</router-link>
				</li>

				<li>
					<router-link to="/blog">
						<h3>Blog</h3>
						<p>Explore feature releases, news stories and the latest from inside our organization.</p>
					</router-link>
				</li>
			</ul>

			<div class="flex flex--end">
				<c-arrow-link to="/resources" class="sub-menu__no-link-style">
					Resources
				</c-arrow-link>
			</div>
		</div>
		<router-link
			v-if="featuredBlog"
			:to="featuredBlog.route"
			class="resources-menu__blog-post sub-menu__no-link-style bg-arnold border-l border-mapplethorpe p-4"
		>
			<template v-if="featuredBlog.images">
				<div
					v-if="featuredBlog.images.wide"
					class="resources-menu__blog-image under"
					:style="{ backgroundImage : `url(${featuredBlog.images.wide})` }"
				/>
				<c-concentric-image
					v-else-if="featuredBlog.images.square"
					:image-type="featuredBlog.images.squareType"
					:cfid="featuredBlog.cfid"
					:color="featuredBlog.images.color ? featuredBlog.images.color : null"
					:image="featuredBlog.images.square"
					:alt-text="featuredBlog.featuredAltTag"
				/>
			</template>
			<div class="over">
				<p class="jaywalk uppercase heavy sz-2">
					Featured Article
				</p>
				<h4 class="m-0"> {{ featuredBlog.headline || featuredBlog.title }} </h4>
				<div class="resources-menu__blog-meta ansel sz-2 mt-2">{{ featuredBlog.author }}&nbsp;<span>|</span>&nbsp;{{ getDate(featuredBlog.published) }}</div>
				<div class="ansel mt-3">{{ featuredBlog.description }}</div>
			</div>
			<c-arrow-link
				class="resources-menu__blog-link sub-menu__no-link-style pin-bottom"
			>
				Read the Article
			</c-arrow-link>
		</router-link>
	</nav>
</template>

<script>
	import CArrowLink from 'src/components/navigation/ArrowLink.vue';
	import { getDate } from '@cognitoforms/utils/utilities';
	import CConcentricImage from 'src/components/content/ConcentricImage.vue';

	export default {
		name: 'CResourcesMenu',
		components: {
			CArrowLink,
			CConcentricImage
		},
		inject: ['siteManifest'],
		computed: {
			featuredBlog() {
				// return most recent featured blog post
				return this.siteManifest.blog.list?.all.filter(article => article.isFeatured === 'true')
					.sort((a, b) => new Date(b.published) - new Date(a.published))[0];
			}
		},
		methods: {
			getDate
		}
	};
</script>

<style lang="scss">
.resources-menu {
	display: flex;

	&__links {
		max-width: 1000px;
		padding-top: 3.5rem;
		padding-right: 3rem;
		padding-bottom: 2rem;
		padding-left: 3rem;
	}

	&__blog {

		&-post {
			flex-shrink: 0;
			width: 35%;
			overflow: hidden;
			text-decoration: none;

			.sm-cc {
				margin-top: -1.6em;

				img {
					width: 170px;
					min-width: initial;
				}

				.concentric--logo-top {

					.mercury {
						stroke: $lange;
					}
				}

				&--harriet {

					.concentric--logo-top {

						.venus {
							stroke: $abraham;
						}
					}
				}

				&--harriet {

					.concentric--gear-top {

						.mercury {
							fill: $harriet;
						}
					}
				}
			}

			h4 {
				color: $matins;
				font-family: $header;
				transition: .2s;
			}

			p {
				font-family: $header;
				letter-spacing: .05em;
			}

			&:hover h4 {
				color: $deep;
				transition: .2s;
			}
		}

		&-image {
			width: 100%;
			height: 150px;
			background-position: center;
			background-size: cover;
		}

		&-meta span {
			margin: 0 5px;
			color: $hitchcock;
		}

		&-link {
			right: 2rem;
		}
	}
}
</style>