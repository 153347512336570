export default {
	async mounted() {
		this.addTooltip();
	},
	async updated() {
		this.addTooltip();
	},
	methods: {
		addTooltip() {
			// Show tooltip for long text strings
			const strings = document.querySelectorAll('[data-tooltip],[data-tooltip=true]');

			if (strings.length) {
				for (const text of strings) {
					if (text.scrollWidth > text.offsetWidth && text.title !== text.innerText) {
						text.title = text.innerText;
					}
					// Clear title tooltip if text fits in scrollWidth
					else if (text.scrollWidth <= text.offsetWidth && text.title)
						text.title = '';
				}
			}
		}
	}
};
