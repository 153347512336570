import focusableEls from '../focusable-elements';

export default {
	methods: {
		trapFocus(e, el) {
			if (e.which === 9) {
				const els = [...el.querySelectorAll(focusableEls)];
				// Handle shift-tabbing when on first element of the dialog,
				// then handle forward tabbing when on last element
				if (e.shiftKey && els[0] === document.activeElement) {
					e.preventDefault();
					els[els.length - 1].focus();
				}
				else if (!e.shiftKey && els[els.length - 1] === document.activeElement) {
					e.preventDefault();
					els[0].focus();
				}
			}
		}
	}
};
