<template>
	<footer class="footer bg-matins white py-5 no-list-style underline-hover relative">
		<div class="wrapper">
			<div class="footer__main gutters mb-4">
				<div>
					<h3 class="casablanca sz-4">Company</h3>
					<ul>
						<li><router-link to="/company/about">About</router-link></li>
						<li><router-link to="/company/careers">Careers</router-link></li>
						<li><router-link to="/newsroom">Newsroom</router-link></li>
						<li><router-link to="/support/160/account-organizations/plans-billing/referral-program">Referral Program</router-link></li>
						<li><router-link to="/supportrequest">Write Us</router-link></li>
					</ul>
				</div>
				<div>
					<h3 class="casablanca sz-4">Support</h3>
					<ul>
						<li><router-link to="/support">User Guides</router-link></li>
						<li><router-link to="/blog">Blog</router-link></li>
						<li><router-link to="/supportrequest">Request Help</router-link></li>
						<li><a href="https://cognitoforms.statuspage.io/" target="_blank" rel="noopener noreferrer">Status</a></li>
					</ul>
				</div>
				<div>
					<h3 class="casablanca sz-4">Industries</h3>
					<ul>
						<li v-for="industry in industries" :key="industry.route">
							<router-link :to="getRoute(industry)">
								{{ industry.navigationTitle || industry.title }}
							</router-link>
						</li>
					</ul>
				</div>
				<div>
					<h3 class="casablanca sz-4">Templates</h3>
					<ul>
						<li v-for="formType in formTypes" :key="formType.route">
							<router-link :to="getRoute(formType)">
								{{ formType.navigationTitle || formType.title }}
							</router-link>
						</li>
					</ul>
				</div>
			</div>
			<ul class="flex gutters mb-3">
				<li>
					<a href="https://www.facebook.com/cognitoforms" class="block casablanca">
						<span class="visually-hidden">Facebook</span>
						<inline svg="facebook" class="icon--fill" />
					</a>
				</li>
				<li>
					<a href="https://twitter.com/cognitoforms" class="block casablanca">
						<span class="visually-hidden">X</span>
						<inline svg="x" class="icon--fill" />
					</a>
				</li>
				<li>
					<a href="https://www.linkedin.com/company/cognito-forms" class="block casablanca">
						<span class="visually-hidden">LinkedIn</span>
						<inline svg="linkedin" class="icon--fill" />
					</a>
				</li>
				<li>
					<a href="https://www.youtube.com/c/cognitoforms" class="block casablanca">
						<span class="visually-hidden">YouTube</span>
						<inline svg="youtube" class="icon--fill" />
					</a>
				</li>
				<li>
					<a href="https://www.instagram.com/cognitoforms/" class="block casablanca">
						<span class="visually-hidden">Instagram</span>
						<inline svg="instagram" class="icon--fill" />
					</a>
				</li>
			</ul>
			<small class="footer__fine-print text-right">
				<ul class="flex flex--end">
					<li><router-link to="/legal/terms">Terms of Service</router-link></li>
					<li class="ml-3"><router-link to="/legal/privacy">Privacy Policy</router-link></li>
				</ul>
				<div>
					Copyright &copy; {{ year }} Cognito LLC, All rights&nbsp;reserved.<br />
					Part of the <strong>Cognito Apps<sup>SM</sup></strong> family of&nbsp;products.
				</div>
			</small>
		</div>
	</footer>
</template>

<script>
	import { urlize } from '@cognitoforms/utils/utilities';

	export default {
		name: 'CFooter',
		inject: ['siteManifest'],
		data() {
			return {
				year: new Date().getFullYear()
			};
		},
		computed: {
			formTypes() {
				return this.siteManifest ? this.getSection(this.siteManifest['form-types']) : [];
			},
			industries() {
				return this.siteManifest ? this.getSection(this.siteManifest.industries) : [];
			}
		},
		methods: {
			getSection(type) {
				return type.list.all.filter(article => article.showInFooter === 'true')
					.sort((a, b) => (a.order > b.order) ? 1 : -1);
			},
			getRoute(item) {
				return `/templates/${urlize(item.canonicalUrl.split('/').pop().toLowerCase())}`;
			}
		}
	};
</script>

<style lang="scss">
.footer {

	li:not(:last-child) {
		margin-bottom: math.div($gutter, 4);
	}

	a {
		color: white;
		text-decoration-color: rgba(white, .7);
	}

	&__main {

		ul {
			border-top: 2px solid $ansel;
			padding-top: 1em;
		}

		@include b-range($b6, $b10) {

			& > div {
				width: 25%;
			}
		}

		@include b-range($b3, $b6) {
			flex-wrap: wrap;

			& > div {
				width: 50%;
			}
		}

		@include b-up($b3) {
			display: flex;
		}

		@include b-up($b6) {

			& > div:nth-last-child(-n+2) ul {
				columns: 2;
				column-gap: 1em;
				column-width: 9em;

				& > * {
					break-inside: avoid;
				}
			}
		}

		@include b-up($b10) {

			& > div {
				flex-shrink: 0;
				width: 15%;
			}

			& > div:nth-last-child(-n+2) {
				flex-grow: 1;
				flex-shrink: unset;
			}
		}
	}

	:root &.page-has-sticky-cta {
		// roughly the max height of a reasonably wordy CTA on mobile
		padding-bottom: 140px;
	}

	@media print {

		h3 {
			color: $matins;
		}

		&,
		a {
			background: transparent;
			color: $ansel;
		}
	}
}

</style>