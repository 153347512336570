import focusableEls from '@cognitoforms/shared-components/src/focusable-elements';

export default {
	props: {
		showMenu: { type: Boolean, required: true }
	},
	data() {
		return {
			focusable: null,
			visible: false
		};
	},
	destroyed() {
		document.removeEventListener('keydown', this.checkTabs);
	},
	watch: {
		showMenu() {
			if (this.showMenu) {
				this.focusable = [...this.$el.querySelectorAll(focusableEls)];
				document.addEventListener('keydown', this.checkTabs);
			}
			else {
				document.removeEventListener('keydown', this.checkTabs);
			}

			setTimeout(() => {
				this.visible = this.showMenu;
			});
		},
		async visible() {
			await this.$nextTick();
			if (this.visible && document.documentElement.getAttribute('data-whatintent') === 'keyboard')
				this.focusable[0].focus();
		}
	},
	methods: {
		checkTabs(e) {
			// only checking for tab key, which is 9
			if (e.which === 9) {
				if ((e.shiftKey && this.focusable[0] === document.activeElement) ||
					(!e.shiftKey && this.focusable[this.focusable.length - 1] === document.activeElement)) {
					e.preventDefault();
					this.$emit('close');
				}
				else if (!this.focusable.includes(document.activeElement)) {
					e.preventDefault();
					this.focusable[0].focus();
				}
			}
		}
	}
};