<template>
	<transition
		name="drop-down"
		:duration="150"
		@after-enter="$emit('after-enter')"
	>
		<div
			v-show="visible"
			v-click-outside="handleClick"
			v-click-anchor="closeNav"
			class="sub-menu"
		>
			<div class="sub-menu__main">
				<slot />
			</div>
		</div>
	</transition>
</template>

<script>
	import Vue from 'vue';
	import clickOutside from '@cognitoforms/shared-components/src/utilities/clickOutside';
	import clickAnchor from '@cognitoforms/shared-components/src/utilities/clickAnchor';
	import headerFocus from 'src/mixins/header-focus';

	Vue.directive('click-outside', clickOutside);
	Vue.directive('click-anchor', clickAnchor);

	export default {
		name: 'CSubMenu',
		mixins: [headerFocus],
		data() {
			return {
				isHiddenOnDesktop: true
			};
		},
		watch: {
			visible() {
				this.checkDisplayStyle();
			}
		},
		mounted() {
			this.checkDisplayStyle();
			window.addEventListener('resize', this.checkDisplayStyle);
		},
		beforeDestroy() {
			document.removeEventListener('resize', this.checkDisplayStyle);
		},
		methods: {
			handleClick() {
				if	(this.visible && !this.isHiddenOnDesktop)
					this.$emit('close');
			},
			closeNav() {
				this.$emit('close');
			},
			async checkDisplayStyle() {
				// Check if sub menu is set to "display: none;" as it will be on mobile viewports.
				// If it is, we don't want to close the menu
				await this.$nextTick();
				this.isHiddenOnDesktop = window.getComputedStyle(this.$el).display === 'none';
			}
		}
	};
</script>

<style lang="scss">
$bpsubnav: 1600px;

.sub-menu {
	position: absolute;
	right: 0;
	z-index: $z-sub-menu;
	width: 100%;
	background-color: white;
	will-change: transform, opacity;

	&__main a:not(.sub-menu__no-link-style) {
		display: inline-block;
		margin-bottom: 2rem;
		text-decoration: none;

		h3 {
			display: block;
			margin-bottom: .6rem;
			color: $matins;
			font-size: inherit;
			font-weight: bold;
			letter-spacing: .05em;
			text-decoration: underline;
			text-transform: uppercase;
			transition: .2s;
		}

		p {
			color: $ansel;
		}

		&:hover h3,
		&.router-link-exact-active h3 {
			color: $deep;
			transition: .2s;
		}
	}

	nav > :first-child {
		flex-grow: 1;
		width: 100%;
		margin: 0 auto;
	}

	ul {
		column-gap: 4rem;
		column-rule: 2px solid $brady;
	}

	@include b-down($b10) {
		display: none;
	}

	@include b-up($bpsubnav) {
		max-width: 1500px;
	}
}
</style>