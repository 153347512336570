<template>
	<component
		:is="$amp ? 'c-amp-sidebar' : 'div'"
		id="mobile-nav"
		:sidebar-id="$amp ? 'navsidebar' : null"
		:layout="$amp ? 'nodisplay' : null"
		:sidebar-position="$amp ? 'right' : null"
		class="mobile-nav"
		:class="{ 'b10:hide' : !$amp, 'mobile-nav--amp' : $amp }"
	>
		<div v-if="$amp" class="mobile-nav-logo bg-white flex flex--sb flex--align">
			<router-link to="/" class="page-header__logo block">
				<inline svg="logos/logo" class="page-header__logo--amp center" />
			</router-link>
			<div role="button" on="tap:navsidebar.close" tabindex="0" class="page-header__hamburger button--no-style inline is-active">
				<span class="hamburger matins"></span>
			</div>
		</div>
		<c-searchbar ref="search" placeholder="Search" class="bg-air" @search="setMobileMenu(false)" />
		<slot />
	</component>
</template>

<script>
	import CSearchbar from 'src/components/navigation/Searchbar.vue';
	import CAmpSidebar from '@cognitoforms/shared-components/src/components/navigation/CAmpSidebar.vue';

	export default {
		name: 'CMobileNav',
		components: { CSearchbar, CAmpSidebar }
	};
</script>

<style lang="scss">
@mixin portrait {
	@media screen and (max-aspect-ratio: 2/3) {
		@content;
	}
}

@mixin landscape {
	@media screen and (min-aspect-ratio: 2/3) {
		@content;
	}
}

.mobile-nav {
	display: flex;
	position: absolute;
	flex-direction: column;
	width: 100%;
	height: calc(100vh - 75px);
	background-color: white;

	@include b-up($b4) {
		@include portrait {
			font-size: calc(12px + .5vw);
		}
	}

	@include landscape {

		&-content {
			display: flex;
		}
	}

	&:not(.mobile-nav--amp) {
		z-index: $z-mobile-menu;
	}

	&--amp {
		max-width: unset;
		height: 100%;

		.mobile-nav-content {
			position: relative;
		}

		/* Menu before AMP initialization */
		html:not([amp-version]) & {
			display: none;
		}
	}

	&-logo {
		height: 75px;
		box-shadow: inset 0 -2px 0 0 $brady;
		padding-right: 2rem;
		padding-left: 2rem;

		img {
			width: 225px;
		}
	}

	&-content {
		flex-grow: 1;
		overflow: hidden;

		ul[class*='-active'] {
			position: absolute;
		}
	}

	&[aria-hidden='false'] {
		display: block !important;
	}

	&[aria-hidden='true'] {
		display: none !important;
	}

	.search {
		color: $deep;
		font-size: 16px;

		@include landscape {

			input {
				padding: .5em 2rem;
			}

			button {
				margin-right: 1.5rem;
			}
		}

		@include portrait {

			input {
				padding: 1em 2rem;
			}

			button {
				margin-right: 1.5em;
			}
		}
	}

	&-menu {

		&[aria-hidden='true'] {
			display: none !important;
		}

		&[aria-hidden='false'] {
			display: block !important;
		}

		&.no-list-style {
			flex: 1 1 auto;
			width: 100%;
			min-height: 100px;
			overflow-y: scroll;
			-ms-overflow-style: none;
			scrollbar-width: none;
			padding-top: 1em;
			padding-bottom: 1em;

			&::-webkit-scrollbar {
				display: none;
			}

			@include portrait {
				padding-right: 4em;
				padding-left: 4em;

				&:first-child {
					padding-top: 3em;
				}
			}

			@include landscape {
				padding-right: 2em;
				padding-left: 2em;

				&:first-child {
					padding-top: 2em;
				}
			}

			li {
				display: flex;
				align-items: center;
				margin-bottom: 1em;
				font-family: $header;

				a,
				button:not(.mobile-nav-back) {
					border-bottom: 2px solid transparent;
					color: $matins;
					font-size: 1.5em;
					text-decoration: none;

					&:hover {
						border-bottom: 2px solid $thetis;
						color: $deep;
					}

					&.mobile-nav-menu__main-item.router-link-active,
					&.router-link-exact-active {
						border-bottom: 2px solid $deep;
						color: $deep;
					}

					&.arrow-link {
						border: none;
						margin-top: 1em;
						color: $deep;
						font-size: 1em;

						&:hover {
							color: $matins;
						}
					}
				}

				.i-chevron-left {
					margin-right: 2.5px;
					margin-left: calc(-1 * 1.25rem + -1 * 2.5px);
					pointer-events: none;
				}

				&:last-child {
					margin-bottom: 12em;
				}

				@include b-up($b4) {
					@include portrait {
						margin-bottom: 1.75em;
						text-align: center;
					}
				}
			}
		}
	}

	&-back {
		letter-spacing: .8px;

		&:hover {
			color: $poseidon;
		}
	}

	&-cta {
		position: fixed;
		bottom: 0;
		right: 0;
		z-index: $z1;
		justify-content: center;

		@include portrait {
			left: 0;
			background-image: linear-gradient(to top, white 80%, rgba(white, 0));
			padding: 1em;
			pointer-events: none;

			& * {
				pointer-events: all;
			}

			& > :first-child {
				margin-right: 1rem;
			}

			@include b-up($b4) {

				a {
					max-width: 200px;
					margin-bottom: 1em;
					font-size: 1.5em;
				}
			}
		}

		@include landscape {
			flex-direction: column;
			padding: 2rem;

			& > :first-child {
				margin-bottom: 1rem;
			}

			a {
				min-width: 150px;
			}
		}

		a {
			width: 100%;
		}
	}

	.page-header__logo--amp {
		height: 30px;
	}
}
</style>