<template>
	<c-avatar
		:avatar="$store.profile.avatar"
		:size="size"
		@error="handleError"
	/>
</template>

<script lang="ts">
	import CAvatar from './Avatar.vue';
	import type { PropType } from 'vue';
	import { defineComponent } from 'vue';
	type Size = 's' | 'm' | 'l';

	export default defineComponent({
		components: {
			CAvatar
		},
		inject: ['$store'],
		props: {
			size: {
				type: String as PropType<Size>,
				default: 's'
			}
		},

		methods: {
			handleError() {
				this.$store.updateProfile({ avatarUrl: ' ' });
			}
		}
	});
</script>