<template>
	<div v-if="avatar || icon || test" class="avatar flex" :class="[{'flex--align gap-3': (name || email) && size != 'l', 'avatar--icon': icon}, 'avatar--' + size]">
		<template v-if="test">
			<div class="avatar__image">
				<svg viewBox="0 0 300 300">
					<circle cx="150" cy="150" r="150" fill="currentColor" class="abraham" stroke="transparent" stroke-width="20" stroke-dasharray="45 45"></circle>
					<text x="50%" y="68%" style="transform: scale(1);  transform-origin: 50%;" text-anchor="middle" class="avatar__initials icon--fill matins heavy">CT</text>
				</svg>
			</div>
		</template>
		<div v-else-if="icon" class="avatar__image">
			<inline :svg="icon" />
		</div>
		<template v-else>
			<img v-if="displayAsImg" :src="computedAvatar" class="avatar__image flex--no-shrink mb-0 block" :alt="name ? '' : 'profile image'" @error="$emit('error');" />
			<div v-else class="avatar__image flex--no-shrink" v-html="computedAvatar" />
		</template>
		<div v-if="name || email" :class="{'avatar--nowrap': !wrap, 'italic': isDeleted}">
			<div v-if="name" class="avatar__name" :data-tooltip="!alwaysShowTooltip" :title="title">{{ name }}</div>
			<div v-if="email" class="avatar__email" data-tooltip :title="title">{{ email }} </div>
		</div>
	</div>
</template>

<script lang="ts">
	import longTextTooltip from '../../mixins/long-text-tooltip';
	import type { PropType } from 'vue';
	import { defineComponent } from 'vue';
	type Size = 's' | 'm' | 'l';
	type GroupAvatarIcon = 'internal' | 'public' | 'role' | 'users' | 'person' | 'email' | 'lock';

	export default defineComponent({
		mixins: [ longTextTooltip ],
		props: {
			avatar: {
				type: String,
				default: null
			},
			size: {
				type: String as PropType<Size>,
				default: 's'
			},
			icon: {
				type: String as PropType<GroupAvatarIcon>,
				default: undefined
			},
			name: {
				type: String,
				default: null
			},
			wrap: {
				type: Boolean,
				default: false
			},
			isDeleted: {
				type: Boolean,
				default: false
			},
			test: {
				type: Boolean,
				default: false
			},
			email: {
				type: String,
				default: null
			},
			alwaysShowTooltip: {
				type: Boolean,
				default: false
			}
		},
		computed: {
			computedAvatar() {
				if (this.avatar && this.size === 'l') {
					if (this.avatar.includes('graph.facebook.com'))
						return this.avatar + (this.avatar.includes('?') ? '&' : '?') + 'width=300&height=300';
					if (this.avatar.includes('googleusercontent.com/'))
						return this.avatar.replace('s96-c', 's300-c');
				}
				return this.avatar;
			},
			displayAsImg() {
				return (this.computedAvatar && this.computedAvatar.startsWith('http'));
			},
			title() {
				return this.$attrs.title;
			}
		}
	});
</script>

<style lang="scss">
.avatar {

	&__image {
		width: $size__avatar;
		height: $size__avatar;
		background-color: white;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		border-radius: 50%;
		object-fit: cover;

		.i-simple {
			width: $size__icon;
		}
	}

	&--s {

		.avatar__image {
			width: $size__avatar--small;
			height: $size__avatar--small;
		}

		.i-simple {
			width: $size__icon--small;
		}
	}

	&--icon .avatar__image {
		display: flex;
		align-items: center;
		justify-content: center;
		background: $brady;
		color: $matins;
	}

	:root &__initials {
		font-family: $header;
		font-size: 140.7354px;
		font-weight: 900;
		user-select: none;
	}

	&.app-nav__avatar-mobile .avatar__image {
		// Dimensions get overridden in AvatarUpload.vue
		width: 100%;
		height: 100%;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		border-radius: 50%;
	}
	min-width: 0;

	&--nowrap {
		min-width: 0;

		> div {
			@include nowrap;
		}
	}
}

</style>