<template>
	<div class="nav__container" :class="{ 'nav__container--no-amp' : !$amp }">
		<c-skip-link
			message="Skip Main Navigation"
			target-id="main-content"
		/>
		<template v-if="$amp && !isLight">
			<amp-position-observer
				intersection-ratios="1"
				on="exit:scrollAnimation.start;"
				layout="nodisplay"
			>
			</amp-position-observer>
			<amp-animation id="scrollAnimation" layout="nodisplay">
				<!-- eslint-disable-next-line vue/no-v-html -->
				<script type="application/json" v-html="ampAnimation"></script>
			</amp-animation>
		</template>
		<div
			class="nav__wrapper nav__wrapper--fixed w-100 print:static"
			role="group"
			:class="{
				'nav__wrapper--scrolled' : scrolled,
				'nav__wrapper--light' : isLight
			}"
		>
			<header
				ref="header"
				class="nav px flex flex--sb flex--align"
			>
				<div v-if="$amp && !isLight" class="nav__amp-bg opacity-0 pin-left-0 pin-right-0 pin-top-0 pin-bottom-0"></div>
				<router-link to="/" class="nav__logo flex over" aria-label="Home page" @click.native="clickLogo()">
					<inline svg="logos/logo" />
				</router-link>
				<nav v-show="!$amp" class="nav__nav b5:flex flex--center flex--wrap flex--align h-100 b10:show">
					<ul v-show="!searchIsActive" data-group="search" class="nav__menu-items flex flex--align no-list-style h-100 mr-2">
						<li>
							<a
								href="/product"
								aria-haspopup="true"
								aria-controls="product-menu"
								:aria-expanded="activeMenuSection === 'product' ? 'true' : 'false'"
								class="flex flex--align h-100 matins gilroy no-underline"
								:class="{'is-active' : activeMenuSection === 'product', 'router-link-active' : highlightSection('product') }"
								@click.prevent="clickOpenMenuSection('product')"
							>
								<span class="flex flex--align flex--center p-0">
									Product
									<inline svg="chevron-down" class="nav__submenu-dropicon" focusable="false" />
								</span>
							</a>
						</li>
						<li>
							<router-link to="/templates" class="flex flex--align h-100 matins gilroy no-underline">
								<span class="flex flex--align flex--center p-0">Templates</span>
							</router-link>
						</li>
						<li>
							<router-link to="/pricing" class="flex flex--align h-100 matins gilroy no-underline">
								<span class="flex flex--align flex--center p-0">Pricing</span>
							</router-link>
						</li>
						<li>
							<a
								href="/resources"
								aria-haspopup="true"
								aria-controls="resources-menu"
								:aria-expanded="activeMenuSection === 'resources' ? 'true' : 'false'"
								class="flex flex--align h-100 matins gilroy no-underline"
								:class="{'is-active' : activeMenuSection === 'resources', 'router-link-active' : highlightSection('resources') }"
								@click.prevent="clickOpenMenuSection('resources')"
							>
								<span class="flex flex--align flex--center p-0">
									Resources
									<inline svg="chevron-down" class="nav__submenu-dropicon" focusable="false" />
								</span>
							</a>
						</li>
					</ul>
					<c-searchbar v-show="searchIsActive" ref="search" data-group="search" :aria-hidden="searchIsActive ? 'false' : 'true'" placeholder="Search" class="search--animate bg-white mr-1" />
					<div class="flex flex--align">
						<button
							class="button--no-style my-0 py-0"
							:title="searchButtonTitle"
							:aria-expanded="searchIsActive ? 'true' : 'false'"
							aria-controls="search-bar"
							aria-haspopup="true"
							role="switch"
							:aria-checked="searchIsActive ? 'true' : 'false'"
							@click="toggleSearch"
						>
							<span :aria-hidden="searchIsActive ? 'true' : 'false'"><inline svg="search" focusable="false" /></span>
							<span :aria-hidden="searchIsActive ? 'false' : 'true'"><inline svg="ex" focusable="false" /></span>
						</button>
					</div>
					<ul v-show="!isAuthenticated" class="no-list-style ml-4 mr-3 my-1">
						<li><a id="login-button" href="/login" class="button button--secondary">Log In</a></li>
						<li><a id="signup-button" href="/signup?source=signup" class="button button--primary bg-deep ml-3">Sign Up</a></li>
					</ul>
					<button
						v-show="isAuthenticated"
						class="nav__avatar button--no-style ml-3 mr-3"
						:class="{ 'is-active' : showAuthDropdown }"
						:title="showAuthDropdown ? 'Close' : 'Open'"
						aria-controls="user-menu"
						@click="toggleAuthDropdown(!showAuthDropdown)"
					>
						<c-store-provided-avatar />
					</button>
				</nav>
				<div v-if="$amp" role="button" on="tap:navsidebar.toggle" tabindex="0" class="nav__hamburger button--no-style inline over">
					<span class="hamburger"></span>
				</div>
				<button
					v-else
					class="nav__hamburger button--no-style b10:hide matins"
					:aria-expanded="showMobileMenu"
					aria-haspopup="true"
					aria-controls="mobile-nav"
					role="switch"
					:aria-checked="showMobileMenu ? 'true' : 'false'"
					:class="{ 'is-active' : showMobileMenu }"
					:title="hamburgerButtonTitle"
					@click="setMobileMenu(!showMobileMenu)"
				>
					<span class="hamburger" />
				</button>
			</header>
			<template v-if="!$amp">
				<c-sub-menu
					id="resources-menu"
					data-group="desktop"
					:aria-hidden="showMenuSection && activeMenuSection === 'resources' ? 'false' : 'true'"
					:show-menu="showMenuSection && activeMenuSection === 'resources'"
					@close="activeMenuSection === 'resources' ? setMenuSection(false) : null"
				>
					<c-resources-menu @close="closeSubMenu" />
				</c-sub-menu>
				<c-sub-menu
					id="product-menu"
					data-group="desktop"
					:aria-hidden="showMenuSection && activeMenuSection === 'product' ? 'false' : 'true'"
					:show-menu="showMenuSection && activeMenuSection === 'product'"
					@close="activeMenuSection === 'product' ? setMenuSection(false) : null"
				>
					<c-product-menu @close="closeSubMenu" />
				</c-sub-menu>
				<c-auth-menu
					:name="name"
					:show-menu="showAuthDropdown"
					:org-code="orgCode"
					@logout="logout"
					@close="toggleAuthDropdown(false)"
				/>
			</template>
			<component :is="$amp ? 'div' : 'transition'" :name="$amp ? null : 'drop-down'" :duration="$amp ? null : 150">
				<c-mobile-nav
					id="mobile-nav"
					:aria-hidden="!showMobileMenu"
				>
					<div class="mobile-nav-content">
						<c-amp-menu v-if="$amp && productManifest" :manifest="productManifest" :highlight-section="highlightSection" />
						<div v-else :name="direction">
							<ul
								id="mobile-main-menu"
								key="1"
								:aria-hidden="!!activeMenuSection ? 'true' : 'false'"
								class="mobile-nav-menu no-list-style"
								data-group="mobile"
							>
								<li>
									<a href="/product" aria-controls="mobile-product-menu" :class="{ 'router-link-exact-active' : highlightSection('product') }" @click.prevent="setMenuSection('product')">Product</a>
									<inline svg="chevron-right" class="ml-1" />
								</li>
								<li><router-link to="/templates" class="mobile-nav-menu__main-item" @click.native="setMobileMenu(false)">Templates</router-link></li>
								<li><router-link to="/pricing" class="mobile-nav-menu__main-item" @click.native="setMobileMenu(false)">Pricing</router-link></li>
								<li>
									<a href="/resources" aria-controls="mobile-resources-menu" :class="{ 'router-link-exact-active' : highlightSection('resources') }" @click.prevent="setMenuSection('resources')">Resources</a>
									<inline svg="chevron-right" class="ml-1" />
								</li>
							</ul>
							<c-resources-mobile-menu
								key="2"
								:aria-hidden="activeMenuSection !== 'resources'"
								data-group="mobile"
								@back="setMenuSection(false)"
								@close="setMobileMenu(false)"
							/>
							<c-product-mobile-menu
								key="3"
								:aria-hidden="activeMenuSection !== 'product'"
								:manifest="productManifest"
								data-group="mobile"
								@back="setMenuSection(false)"
								@close="setMobileMenu(false)"
							/>
						</div>
						<div v-show="!isAuthenticated" class="mobile-nav-cta flex flex--align no-list-style">
							<a id="login-button" href="/login" class="button button--secondary sz-4">Log In</a>
							<a id="signup-button" href="/signup?source=signup" class="button button--primary bg-deep sz-4">Sign Up</a>
						</div>
						<c-mobile-auth-menu
							v-if="!$amp"
							v-show="isAuthenticated"
							:org-code="orgCode"
							:name="name"
							@logout="logout"
							@closeMenu="setMobileMenu(false)"
						/>
					</div>
				</c-mobile-nav>
			</component>
		</div>
	</div>
</template>

<script>
	import CSearchbar from 'src/components/navigation/Searchbar.vue';
	import CSubMenu from 'src/components/navigation/SubMenu.vue';
	import CMobileNav from 'src/components/navigation/MobileNav.vue';
	import CResourcesMenu from 'src/components/navigation/ResourcesMenu.vue';
	import CResourcesMobileMenu from 'src/components/navigation/ResourcesMobileMenu.vue';
	import CProductMenu from 'src/components/navigation/ProductMenu.vue';
	import CProductMobileMenu from 'src/components/navigation/ProductMobileMenu.vue';
	import CStoreProvidedAvatar from '@cognitoforms/shared-components/src/components/auth/StoreProvidedAvatar.vue';
	import CAmpMenu from 'src/components/navigation/AmpMenu.vue';
	import CAuthMenu from 'src/components/navigation/AuthMenu.vue';
	import CMobileAuthMenu from 'src/components/navigation/MobileAuthMenu.vue';
	import CSkipLink from 'src/components/navigation/SkipLink.vue';
	import trapFocus from '@cognitoforms/shared-components/src/mixins/trap-focus';
	import focusableEls from '@cognitoforms/shared-components/src/focusable-elements';
	import { getActiveSession } from '@cognitoforms/api/services/session';
	import { logout } from '@cognitoforms/api/services/session-service';
	import CLogo from '@cognitoforms/shared-components/src/assets/graphics/logos/logo.svg';
	import sessionConsumer from '@cognitoforms/shared-components/src/mixins/session-consumer';

	export default {
		name: 'Nav',
		components: {
			CSearchbar,
			CSubMenu,
			CMobileNav,
			CResourcesMenu,
			CResourcesMobileMenu,
			CProductMenu,
			CProductMobileMenu,
			CStoreProvidedAvatar,
			CAmpMenu,
			CAuthMenu,
			CMobileAuthMenu,
			CSkipLink,
			CLogo
		},
		mixins: [trapFocus, sessionConsumer],
		inject: ['$store', 'siteManifest'],
		data() {
			return {
				searchIsActive: false,
				showMobileMenu: false,
				activeMenuSection: null,
				direction: 'slide-left',
				focusable: [],
				showAuthDropdown: false,
				lastFocusedEl: null,
				scrolled: false,
				ampAnimation: JSON.stringify({
					'selector': '.nav__amp-bg',
					'duration': '1s',
					'fill': 'forwards',
					'keyframes': { 'opacity': '.95' }
				})
			};
		},
		head() {
			return {
				htmlAttrs: {
					'data-overlay-active': this.showOverlay
				}
			};
		},
		computed: {
			productManifest() {
				return this.siteManifest.product;
			},
			searchButtonTitle() {
				return this.searchIsActive ? 'Close Search' : 'Open Search';
			},
			hamburgerButtonTitle() {
				return this.showMobileMenu ? 'Close Menu' : 'Open Menu';
			},
			showMenuSection() {
				return !!this.activeMenuSection;
			},
			name() {
				return this.$store.profile.lastName ? this.$store.profile.name : this.$store.profile.firstName;
			},
			showOverlay() {
				return this.showAuthDropdown || this.showMenuSection;
			},
			isLight() {
				return this.$route.meta.header === 'light' && !this.scrolled && !this.showMenuSection;
			},
			orgCode() {
				return this.session?.value?.orgCode;
			}
		},
		watch: {
			showMenuSection(value) {
				if (!value && document.documentElement.getAttribute('data-whatintent') === 'keyboard')
					this.lastFocusedEl.focus();
			},
			showAuthDropdown(value) {
				if (!value && document.documentElement.getAttribute('data-whatintent') === 'keyboard')
					this.lastFocusedEl.focus();
			}
		},
		created() {
			if (this.$isServer) return;

			getActiveSession().then(session => {
				if (session.isAuthenticated) {
					this.$store.loadProfile();
				}
			}).catch(() => {});

			document.addEventListener('keyup', this.handleEscape);
			document.addEventListener('keydown', this.checkTabs);
			document.addEventListener('scroll', this.onScroll);
			window.addEventListener('resize', this.onResize);
			document.documentElement.setAttribute('data-mobile-nav-active', false);
		},
		beforeDestroy() {
			if (this.$isServer) return;

			document.removeEventListener('keyup', this.handleEscape);
			document.removeEventListener('keydown', this.checkTabs);
			document.removeEventListener('scroll', this.onScroll);
			window.removeEventListener('resize', this.onResize);
			document.documentElement.setAttribute('data-mobile-nav-active', false);
			document.removeEventListener('scroll', this.onScroll);
		},
		async mounted() {
			await this.$nextTick();
			this.focusable = [...this.$el.querySelectorAll(focusableEls)];
			this.lastFocusedEl = document.activeElement;
			this.onResize();
			this.onScroll();
		},
		methods: {
			toggleSearch() {
				this.searchIsActive = !this.searchIsActive;
			},
			clickOpenMenuSection(value) {
				this.lastFocusedEl = document.activeElement;
				this.activeMenuSection = value;
			},
			setMenuSection(value) {
				if (value) {
					this.lastFocusedEl = document.activeElement;
					this.activeMenuSection = value;
					this.direction = 'slide-left';
				}
				else {
					this.activeMenuSection = null;
					this.direction = 'slide-right';
				}
			},
			toggleAuthDropdown(value) {
				if (value)
					this.lastFocusedEl = document.activeElement;
				this.showAuthDropdown = value;
			},
			setMobileMenu(value) {
				this.showMobileMenu = value;
				document.documentElement.setAttribute('data-mobile-nav-active', value);
			},
			async focusSearch() {
				if (this.searchIsActive)
					this.$refs.search.$el.querySelector('input').focus();
			},
			handleEscape(e) {
				if (e.key === 'Escape' || e.keyCode === 27) {
					this.searchIsActive = false;
					this.setMobileMenu(false);
					this.setMenuSection(false);
					this.toggleAuthDropdown(false);
				}
			},
			checkTabs(e) {
				if (this.showMobileMenu)
					this.trapFocus(e);
			},
			closeSubMenu() {
				this.activeMenuSection = null;
				document.removeEventListener('keyup', this.handleEscape);
				document.removeEventListener('keydown', this.checkTabs);
				document.documentElement.setAttribute('data-mobile-nav-active', false);
			},
			highlightSection(section) {
				const paths = section === 'resources' ? ['support', 'resources', 'blog'] : ['product'];
				const currentPath = this.$route.fullPath.toLowerCase().split('/')[1];
				return !!paths.includes(currentPath);
			},
			onScroll() {
				this.scrolled = window.pageYOffset > 0;
			},
			async onResize() {
				await this.$nextTick();
				const element = document.querySelector('.nav__wrapper');

				if (element) {
					const isFixed = window.getComputedStyle(element).position === 'fixed';

					document.documentElement.setAttribute('data-header-fixed', isFixed);
				}
			},
			logout() {
				logout().then(()=> {
					this.toggleAuthDropdown(false);
					this.setMobileMenu(false);
				});
			},
			clickLogo() {
				window.scrollTo({ top: 0, behavior: 'smooth' });
			}
		}
	};
</script>

<style lang="scss" src="@cognitoforms/styles/global/_motion.scss"></style>
<style lang="scss" src="@cognitoforms/styles/website/hamburger.scss"></style>

<style lang="scss">
	@mixin portrait {
		@media screen and (max-aspect-ratio: 2/3) {
			@content;
		}
	}

	@mixin landscape {
		@media screen and (min-aspect-ratio: 2/3) {
			@content;
		}
	}

	@mixin overlay-active($value) {

		[data-overlay-active='#{$value}'] {
			@content;
		}
	}

	@mixin mobile-nav-active($value) {

		[data-mobile-nav-active='#{$value}'] {
			@content;
		}
	}

	@mixin menu-item-scrolled {
		height: 100%;
		margin: 0;
		padding: 0 1vw;
	}

	@mixin scrolled-header {
		background-color: white;
		box-shadow: inset 0 -2px 0 0 rgba($ansel, .2);
	}

	// When mobile nav is open

	@include mobile-nav-active(true) {
		@media (hover: hover) {
			position: fixed;
			overflow-y: scroll;
			width: 100%;
		}

		@media (hover: none) {
			overflow: hidden;
		}

		@include ie {
			position: fixed;
			overflow-y: scroll;
			width: 100%;
		}

		.nav {
			z-index: $z-mobile-menu;
			background-color: white;
		}
	}

	// When mobile nav is closed
	@include mobile-nav-active(false) {
		@media (min-height: $b4) {

			.nav__wrapper--light {

				.i-logotype,
				.i-logotype * {
					fill: white;
				}

				.hamburger {
					color: white;
				}
			}
		}
	}

	// When dropdown is open
	@include b-up($b10) {

		[id='app'] {

			& > *:not(.nav__container) {
				transition: filter .25s;
			}
		}

		@include overlay-active(true) {

			[id='app'] {

				&::before {
					position: fixed;
					top: 0;
					z-index: $z-page-overlay;
					width: 100%;
					height: 100%;
					background-color: $matins;
					opacity: .5;
					content: '';
				}

				& > *:not(.nav__container) {
					filter: blur(5px);
					pointer-events: none;
					transition: filter .25s;
				}

				.nav {
					@include scrolled-header;
				}
			}
		}
	}

	// Submenu is open
	@include overlay-active(true) {

		.nav__menu-items a {

			span {
				@include menu-item-scrolled;
			}

			@media (hover: hover) {

				&:hover span {
					border-bottom: 2px solid $deep;
				}
			}

			&.router-link-active span {
				border-bottom: 2px solid $thetis;
			}
		}
	}

	// Submenu is closed
	@include overlay-active(false) {
		@include b-up($b10) {
			@media (min-height: $b4) {

				.nav__wrapper--light {

					.i-logotype,
					.i-logotype * {
						fill: white;
					}

					.button--primary {
						background-color: $air;
						color: $midnight;
					}

					.button--secondary {
						border: 1px solid $air;
						color: $air;

						&:hover {
							color: $midnight;
						}
					}

					.nav__menu-items a {
						color: rgba(white, .8);

						@media (hover: hover) {

							&:hover span {
								border-bottom: 2px solid $air;
								color: white;
							}
						}

						&.router-link-active {
							color: white;
						}
					}

					.i-ex,
					.i-search {
						color: white;
					}

					.search__button .i-search {
						color: $matins;
					}
				}
			}
		}
	}

	.nav {
		height: 75px;
		transition: .2s;

		@include b-up($b10) {
			height: 100px;
		}

		&__container {
			z-index: $z-header;
		}

		&__wrapper {
			top: 0;
			z-index: $z-header;

			// Only fixed on tall enough screens
			@media (min-height: $b4) {

				&--fixed {
					position: fixed;
				}
			}

			.i-ex {
				color: $deep;
			}

			.hamburger,
			amp-sidebar[open] .hamburger,
			.i-search {
				color: $matins;
			}

			&--scrolled .nav {
				height: 75px;
				background: rgba(white, .95);
				box-shadow: inset 0 -2px 0 0 rgba($ansel, .2);

				&__menu-items a {

					span {
						@include menu-item-scrolled;
					}
				}
			}
		}

		&__amp-bg {
			@include scrolled-header;
		}

		[⚡] &__wrapper--light {
			position: absolute;

			.page-header__logo svg {
				color: white;
			}

			.hamburger {
				color: white;
			}
		}

		&__logo .c-icon {
			width: 224px;

			@include b-down($b10) {
				width: 200px;
			}
		}

		&__nav {
			margin-right: math.div(-$gutter, 2);

			ul,
			li {
				margin-bottom: 0;
			}

			li {
				display: inline-block;
			}

			.search {
				border: 1px solid $platon;
				transform-origin: right;

				.i-search {
					color: $matins;
				}
			}

			& [aria-hidden='false'] {
				display: block;
			}

			& [aria-hidden='true'] {
				display: none;
			}
		}

		&__menu-items {

			li {
				height: 100%;
			}

			a {
				letter-spacing: .3px;
				transition: .4s;

				span {
					height: 2em;
					border-bottom: 2px solid transparent;
					margin: 0 1vw;
					transition: padding .25s, margin .25s, border-bottom .25s, height .25s;
					will-change: padding, margin, border-bottom, height;

					@include ie {
						transition: border-bottom .25s, height .25s;
					}
				}

				@media (hover: hover) {

					&:hover span {
						border-bottom: 2px solid $deep;
					}
				}

				&.router-link-active span {
					border-bottom: 2px solid $thetis;
				}

				&.is-active .i-chevron-down {
					transform: rotate(-180deg);
				}
			}
		}

		&__avatar {
			border: 3px solid transparent;
			border-radius: 50%;
			transition: all .25s;

			&.is-active {
				border: 3px solid $lange;
				opacity: .75;
				transition: all .25s;
				filter: grayscale(100%);
			}

			&:hover {
				border: 3px solid $brady;
				transition: all .25s;
			}
		}

		:root &__submenu-dropicon {
			width: .75rem;
			margin-left: 5px;
			transition: transform .2s;
			pointer-events: none;
		}
	}
</style>