<template>
	<amp-nested-menu layout="fill">
		<ul class="mobile-nav-menu no-list-style">
			<li>
				<button class="button button--no-style" :class="{ 'router-link-exact-active' : highlightSection('product') }" amp-nested-submenu-open>Product</button>
				<inline svg="chevron-down" />
				<div amp-nested-submenu>
					<ul class="mobile-nav-menu no-list-style">
						<li>
							<inline svg="chevron-down" />
							<button class="mobile-nav-back heavy matins button button--no-style" amp-nested-submenu-close>Product</button>
						</li>
						<li v-for="category of categories" :key="category.code">
							<router-link :to="category.route">
								{{ category.title }}
							</router-link>
						</li>
						<li>
							<c-arrow-link to="/product" class="sub-menu__no-link-style">
								Product
							</c-arrow-link>
						</li>
					</ul>
				</div>
			</li>
			<li><router-link to="/templates" class="mobile-nav-menu__main-item">Templates</router-link></li>
			<li><router-link to="/pricing" class="mobile-nav-menu__main-item">Pricing</router-link></li>
			<li>
				<button class="button button--no-style" :class="{ 'router-link-exact-active' : highlightSection('resources') }" amp-nested-submenu-open>Resources</button>
				<inline svg="chevron-down" />
				<div amp-nested-submenu>
					<ul class="mobile-nav-menu no-list-style">
						<li>
							<inline svg="chevron-down" />
							<button class="mobile-nav-back heavy matins button button--no-style" amp-nested-submenu-close>Resources</button>
						</li>
						<li><router-link to="/support">Support</router-link></li>
						<li><router-link to="/industries">Industry Guides</router-link></li>
						<li><router-link to="/blog/tags/customer-stories">Customer Stories</router-link></li>
						<li><router-link to="/blog/tags/best-practices">Best Practices</router-link></li>
						<li><router-link to="/blog">Blog</router-link></li>
						<li>
							<c-arrow-link to="/resources" class="sub-menu__no-link-style">
								Resources
							</c-arrow-link>
						</li>
					</ul>
				</div>
			</li>
		</ul>
	</amp-nested-menu>
</template>

<script>
	import CArrowLink from 'src/components/navigation/ArrowLink.vue';

	export default {
		name: 'CAmpMenu',
		components: {
			CArrowLink
		},
		props: {
			highlightSection: { type: Function, required: true },
			manifest: { type: Object, required: true }
		},
		computed: {
			categories() {
				return this.manifest.list.all.filter(article => this.getChildren(article.cfid).length > 0)
					.sort((a, b) => (a.order > b.order) ? 1 : -1);
			}
		},
		methods: {
			getChildren(cfid) {
				return this.manifest.list.all.filter(article => article.breadcrumbs.includes(cfid));
			}
		}
	};
</script>