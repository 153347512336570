<template>
	<ul id="mobile-product-menu" v-click-anchor="closeMenu" class="mobile-nav-menu no-list-style">
		<li>
			<inline svg="chevron-left" />
			<button aria-controls="mobile-main-menu" class="mobile-nav-back heavy matins button button--no-style" title="Back to Main Menu" @click="$emit('back')">Product</button>
		</li>
		<li v-for="category of categories" :key="category.code">
			<router-link :to="category.route">
				{{ category.title }}
			</router-link>
		</li>
		<li>
			<c-arrow-link to="/product" class="sub-menu__no-link-style">
				Product
			</c-arrow-link>
		</li>
	</ul>
</template>

<script>
	import Vue from 'vue';
	import clickAnchor from '@cognitoforms/shared-components/src/utilities/clickAnchor';
	import CArrowLink from 'src/components/navigation/ArrowLink.vue';

	Vue.directive('click-anchor', clickAnchor);

	export default {
		name: 'CProductMobileMenu',
		components: {
			CArrowLink
		},
		props: {
			manifest: { type: Object, required: true }
		},
		computed: {
			categories() {
				return this.manifest.list.all.filter(article => this.getChildren(article.cfid).length > 0)
					.sort((a, b) => (a.order > b.order) ? 1 : -1);
			}
		},
		methods: {
			closeMenu() {
				this.$emit('close');
			},
			getChildren(cfid) {
				return this.manifest.list.all.filter(article => article.breadcrumbs.includes(cfid));
			}
		}
	};
</script>