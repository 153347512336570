<template>
	<ul id="mobile-resources-menu" v-click-anchor="closeMenu" class="mobile-nav-menu no-list-style">
		<li>
			<inline svg="chevron-left" />
			<button aria-controls="mobile-main-menu" class="mobile-nav-back heavy matins button button--no-style" title="Back to Main Menu" @click="$emit('back')">Resources</button>
		</li>
		<li><router-link to="/support">Support</router-link></li>
		<li><router-link to="/industries">Industry Guides</router-link></li>
		<li><router-link to="/blog/tags/best-practices">Best Practices</router-link></li>
		<li><router-link to="/blog/tags/customer-stories">Customer Stories</router-link></li>
		<li><router-link to="/blog">Blog</router-link></li>
		<li>
			<c-arrow-link to="/resources" class="sub-menu__no-link-style">
				Resources
			</c-arrow-link>
		</li>
	</ul>
</template>

<script>
	import Vue from 'vue';
	import clickAnchor from '@cognitoforms/shared-components/src/utilities/clickAnchor';
	import CArrowLink from 'src/components/navigation/ArrowLink.vue';

	Vue.directive('click-anchor', clickAnchor);

	export default {
		name: 'CResourcesMobileMenu',
		components: {
			CArrowLink
		},
		methods: {
			closeMenu() {
				this.$emit('close');
			}
		}
	};
</script>