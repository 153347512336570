<template>
	<div id="app" v-bind="{ 'data-server-rendered': isPrerendered }" @click="handleClick">
		<c-app-insights />
		<cognito-header v-if="showNavigation" v-show="!isLandingPage" />
		<div id="main-content" tabindex="-1" />
		<slot />
		<cognito-footer v-if="showNavigation" v-show="!isLandingPage" :key="$route.path" />
		<c-tasteful-branch-name v-if="notProduction" />
	</div>
</template>

<script>
	import Header from 'src/components/navigation/Nav.vue';
	import Footer from 'src/components/navigation/Footer.vue';
	import { isWithinFrame, isPrerender } from '@cognitoforms/utils/utilities';
	import { injectBing } from '@cognitoforms/shared-components/src/layouts/bing';
	import CAppInsights from '@cognitoforms/shared-components/src/components/embeds/AppInsights';
	import CTastefulBranchName from '@cognitoforms/shared-components/src/components/embeds/TastefulBranchName.vue';

	export default {
		components: {
			'cognito-header': Header,
			'cognito-footer': Footer,
			CAppInsights,
			CTastefulBranchName
		},
		inheritAttrs: false,
		head: {
			htmlAttrs: {
				class: ['website']
			},
			meta: [
				{ name: 'og:type', content: 'website' }
			]
		},
		computed: {
			notProduction() {
				return process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test';
			},
			showNavigation() {
				if (isWithinFrame()) {
					try {
						// try to access the property to determine whether or not we're hosted on another domain
						// eslint-disable-next-line no-unused-expressions
						window.parent.location.hostname;
					}
					catch (e) {
						return false;
					}
				}
				return true;
			},
			isPrerendered() {
				return isPrerender();
			},
			isLandingPage() {
				return this.$route.path.includes('/blog/l/');
			}
		},
		watch: {
			$route() {
				if (!this.isPrerendered) {
					this.bing();
				}
			}
		},
		beforeDestroy() {
			document.querySelector('#app')?.removeEventListener('scroll', this.handleScroll);
		},
		mounted() {
			if (!this.isPrerendered)
				injectBing();

			// Click-jacking prevention: Non-cognito sites should not be able to frame marketing pages.
			// If not logged in, show login page instead of templates and home page.
			// Show blank page and load requested url in new tab for all other marketing pages.
			if (isWithinFrame()) {
				if (window.location.href.includes('templates'))
					location.replace('/login?returnUrl=' + window.location.pathname);
				else if (window.location.href === window.origin + '/')
					location.replace('/login');
				else {
					location.replace('about:blank');
					window.open(window.location.href);
				}
			}

			this.handleScroll();
			document.addEventListener('scroll', this.handleScroll);
		},
		methods: {
			handleClick(e) {
				if (!e.target.classList.contains('cog-no-same-page-scroll')
					&& e.target.classList.contains('router-link-active')

				) {
					document.documentElement.style.scrollBehavior = 'smooth';
					setTimeout(() => {
						window.scroll(0, 0);
						document.documentElement.style.scrollBehavior = null;
					});
				}
			},
			bing() {
				window.uetq = window.uetq || [];
				window.uetq.push('event', 'page_view', { page_path: this.$route.fullPath });
			},
			handleScroll() {
				if (typeof window.scrollY === 'undefined' || this.$amp)
					return;

				document.body.style.setProperty('--scroll', String(window.scrollY));
			}
		}
	};
</script>

<style lang="scss" src="@cognitoforms/styles/website/common/common.scss"></style>
<style lang="scss" src="@cognitoforms/styles/website/common/utility.scss"></style>

<style lang="scss">
.footer-placeholder {
	height: 500px;
}

// Concentric Rotation
.concentric__container > *:nth-child(even),
:root:root .clockwise {
	transform: rotate(calc(var(--scroll) * .1deg));
}

.concentric__container > *:nth-child(odd),
:root:root .counterclockwise {
	transform: rotate(calc(var(--scroll) * -.1deg));
}

:root .clockwise.slow {
	transform: rotate(calc(var(--scroll) * .05deg));
}

:root .counterclockwise.slow {
	transform: rotate(calc(var(--scroll) * -.05deg));
}

:root .clockwise.fast {
	transform: rotate(calc(var(--scroll) * .2deg));
}

:root .counterclockwise.fast {
	transform: rotate(calc(var(--scroll) * -.2deg));
}

@media (prefers-reduced-motion) {

	:root .concentric__container > *,
	:root:root .clockwise,
	:root:root .counterclockwise {
		transform: rotate(0deg);
	}
}
</style>
