<template>
	<transition name="drop-down" :duration="150">
		<div v-show="visible" v-click-outside="clickOutside" class="auth-dropdown flex flex--column pin-right-0 bg-white b10:show">
			<div class="auth-dropdown__header flex flex--align bg-mapplethorpe p-3">
				<c-store-provided-avatar class="ml-2" />
				<span class="matins ml-2 heavy sz-2">{{ name }}</span>
			</div>
			<ul class="auth-dropdown__content text-center p-4">
				<li><a href="/myaccount" class="mb-2">My Account</a></li>
				<li v-show="orgCode"><a :href="orgCode !== null ? `/${orgCode}` : '/forms'" class="mb-2">Dashboard</a></li>
				<li><router-link to="/support" class="mb-2" @click.native="$emit('close')">Support</router-link></li>
				<li>
					<button class="button button--no-style bg-brady sz-2 px-3 py-2" @click="$emit('logout')">
						Log Out
					</button>
				</li>
			</ul>
		</div>
	</transition>
</template>
<script>
	import Vue from 'vue';
	import CStoreProvidedAvatar from '@cognitoforms/shared-components/src/components/auth/StoreProvidedAvatar.vue';
	import clickOutside from '@cognitoforms/shared-components/src/utilities/clickOutside';
	import headerFocus from 'src/mixins/header-focus';

	Vue.directive('click-outside', clickOutside);

	export default {
		name: 'CAuthDropdown',
		components: {
			CStoreProvidedAvatar
		},
		mixins: [headerFocus],
		props: {
			name: { type: String, default: null },
			orgCode: { type: String, default: null }
		},
		methods: {
			clickOutside() {
				if (this.visible)
					this.$emit('close');
			}
		}
	};
</script>

<style lang="scss">
.auth-dropdown {
	position: absolute;
	z-index: $z-auth-dropdown;
	min-width: 185px;
	max-width: 200px;
	will-change: transform, opacity;

	&__header {
		justify-content: center;

		span {
			@include break-word;
			word-break: break-word;
		}
	}

	&__content {

		a {
			display: inline-block;
			color: $matins;
			text-decoration: none;

			&:hover {
				color: $deep;
			}
		}
	}
}
</style>