import session from '@cognitoforms/api/services/session';

export interface SessionConsumerMixin {
	session: typeof session;
	isAuthenticated: boolean;
}

export default {
	data() {
		return {
			session
		};
	},
	computed: {
		isAuthenticated(): boolean {
			return (this as unknown as SessionConsumerMixin).session.value?.isAuthenticated;
		},
		isSupportUser(): boolean {
			return (this as unknown as SessionConsumerMixin).session.value?.authMethod === '5';
		},
		allowSessionRefresh(): boolean {
			return (this as unknown as SessionConsumerMixin).session.value?.allowSessionRefresh;
		}
	}
};
