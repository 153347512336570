<template>
	<nav class="product-menu">
		<div class="product-menu__links">
			<ul class="pb-2">
				<li v-for="category of categories" :key="category.code">
					<router-link :to="category.route">
						<h3>{{ category.title }}</h3>
						<p>{{ category.menudescription || category.description }}</p>
					</router-link>
				</li>
			</ul>

			<div class="flex flex--end">
				<c-arrow-link to="/product" class="sub-menu__no-link-style">
					Product
				</c-arrow-link>
			</div>
		</div>
		<router-link
			v-show="highlightedFeature"
			:to="highlightedFeature ? highlightedFeature.route : '#'"
			class="product-menu__featured-article sub-menu__no-link-style flex flex--align bg-mapplethorpe py-3 px-4"
		>
			<span class="bg-jaywalk white heavy py-2 px-3 mr-3">NEW</span>
			<h4 class="m-0">{{ highlightedFeature ? (highlightedFeature.headline || highlightedFeature.description) : "" }}</h4>
		</router-link>
	</nav>
</template>

<script>
	import CArrowLink from 'src/components/navigation/ArrowLink.vue';

	export default {
		name: 'CProductMenu',
		components: {
			CArrowLink
		},
		inject: ['siteManifest'],
		computed: {
			manifest() {
				return this.siteManifest.product;
			},
			highlightedFeature() {
				// return highlighted feature thats tagged in the wiki
				return this.manifest.list.all.filter(article => article.isFeatured === 'true')[0];
			},
			categories() {
				return this.manifest.list.all.filter(article => this.getChildren(article.cfid).length > 0)
					.sort((a, b) => (a.order > b.order) ? 1 : -1);
			}
		},
		methods: {
			getChildren(cfid) {
				return this.manifest.list.all.filter(article => article.breadcrumbs.includes(cfid));
			}
		}
	};
</script>

<style lang="scss">
.product-menu {

	&__links {
		padding-top: 3.5rem;
		padding-right: 3rem;
		padding-bottom: 2rem;
		padding-left: 3rem;

		& > ul {
			columns: 3;
			column-width: 9em;
		}
	}

	&__featured-article {
		color: $ansel;
		text-decoration: none;
		transition: .2s;

		span {
			font-family: $header;
			letter-spacing: .05em;
		}

		h4 {
			font-size: 1rem;
		}

		&:hover h4 {
			color: $deep;
			transition: .2s;
		}
	}
}
</style>